import React from "react"
import styled from "styled-components"

const DescriptionList = ({ title, details }) => {
  details = !details || typeof details !== "object" ? [details] : details

  return (
    <List>
      <Title>{title}</Title>
      {details.map((item, index) => (
        <Details key={index}>{item}</Details>
      ))}
    </List>
  )
}

const List = styled.dl`
  &:not(:last-of-type) {
    margin-bottom: 50px;
  }
`

const Title = styled.dt`
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  /* line-height: 1.6; */
  letter-spacing: 0.07em;
  color: #000;
`

const Details = styled.dd`
  font-size: 15px;
  /* line-height: 1.6; */
  color: ${(p) => p.theme.color.textLight};

  /* &:not(:only-of-type) {
    position: relative;

    &::before {
      content: "";
      width: 4px;
      height: 4px;
      position: absolute;
      top: 5px;
      left: -10px;
      background: currentColor;
    }
  } */
`

export default DescriptionList
