import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import Seo from "src/components/SEO"
import SectionFullBleed from "src/components/Section/SectionFullBleed"
import DescriptionList from "src/components/DescriptionList"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseButton from "src/components/Base/BaseButton"
import { PrimaryTitle, SecondaryTitle } from "src/components/Typography"

export const pageQuery = graphql`
  {
    allStrapiJobPosting {
      nodes {
        position_title
        working_languages
        link_to_apply
        organisation {
          name
          logo {
            localFile {
              publicURL
            }
          }
        }
        location
      }
    }
  }
`
const JobsPage = ({ data }) => {
  const jobs = data.allStrapiJobPosting.nodes
  const jobPostings = (
    <Positions>
      {jobs.map(({ position_title, working_languages, link_to_apply, organisation, location }) => {
        if (position_title === 'No open job positions currently, check back soon!') {
          return (
            <Positions>
              <Position>
                <PositionInner>
                  <NoPositionTitle>{position_title}</NoPositionTitle>
                </PositionInner>
              </Position>
            </Positions>
          )
        } else {
          return (
            <Position>
              <PositionInner>
                <PositionTitle>{position_title}</PositionTitle>
                <PositionColumns>
                  <PositionColumn>
                    <DescriptionList
                      title="Location"
                      details={location}
                    />
                  </PositionColumn>
                  <PositionColumn>
                    <DescriptionList
                      title="Working languages"
                      details={working_languages}
                    />
                  </PositionColumn>
                  <PositionColumn>
                    <DescriptionList title="Organization" details={organisation.name} />
                  </PositionColumn>
                  <PositionColumn>
                    <LogoWrapper>
                      <Logo src={organisation.logo[0].localFile.publicURL} alt={organisation.name + ' logo'} />
                    </LogoWrapper>
                  </PositionColumn>
                  <PositionColumn>
                    <PositionButton
                      label="View position"
                      to={link_to_apply}
                    />
                  </PositionColumn>
                </PositionColumns>
              </PositionInner>
            </Position>
          )
        }
      })}
    </Positions>
  )

  return (
    <Wrapper>
      <Seo title="Jobs" />
      <SectionFullBleed
        // tag="Jobs"
        primaryTitle="Jobs"
        title="Do you want to dedicate your life to fighting animal suffering? Learn about positions in our international team."
        image="/images/jobs.jpg"
      />
      <Container narrow>
        <PrimaryTitle>Open positions</PrimaryTitle>
        <Paragraph>
          As Anima International, we work in many countries under different,
          local names. You can see all open positions listed below and the
          organization that runs the recruitment process.
        </Paragraph>
        <Paragraph>
          If you find something you think you would be a good fit for, we
          strongly encourage you to apply. Let’s change the world together.
        </Paragraph>
      </Container>
      <Container>
        {jobPostings}
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 160px;

  ${media.tablet} {
    margin-bottom: 220px;
  }
`

const Container = styled(BaseContainer)``

const Paragraph = styled.p`
  + p {
    margin-top: 1em;
  }
`

const Positions = styled.div`
  margin-top: 80px;

  ${media.tablet} {
    margin-top: 150px;
  }
`

const Position = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
  background: linear-gradient(-20deg, #3ab6da, #50d9d6);
  border-radius: 3px;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.06);
  margin-bottom: 2em;

  &::before {
    content: "";
    position: absolute;
    top: 3px;
    right: 3px;
    bottom: 3px;
    left: 3px;
    background: #fff;
  }

  ${media.tablet} {
    padding: 60px 40px 70px;
  }

  ${media.desktopMedium} {
    padding: 60px 70px 70px;
  }
`

const PositionInner = styled.div`
  position: relative;
`

const PositionTitle = styled(SecondaryTitle)`
  margin-bottom: 50px;
`

const NoPositionTitle = styled(PositionTitle)`
  text-align: center; 
  margin-bottom: 0px;

  ${media.tablet} {
    margin-bottom: -15px;
  }
`

const PositionColumns = styled.div`
  ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`

const PositionColumn = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  ${media.desktop} {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`

const LogoWrapper = styled.div``

const Logo = styled.img`
  width: 70px;
`

const PositionButton = styled(BaseButton)``

export default JobsPage
